/* eslint-disable no-nested-ternary */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { UseUserDetails } from "../../hooks/auth";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftProfile from "../ProfilePictures/SoftProfile";
import SoftTypography from "../SoftTypography";
import { UseSubscriptionLevel } from "../../hooks/company";

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);
  const navigate = useNavigate();
  const isSubscribed = UseSubscriptionLevel();
  const { firstName, lastName, company, image } = UseUserDetails();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SoftBox mr={{ xs: 0, xl: 8 }}>
      {/* <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              route[route.length - 1] === "home" ? white.main : grey[600],
          },
        }}
      >
        <SoftBox
          sx={{
            background: "#ced4da",
            padding: "0.1rem",
            borderRadius: "50%",
          }}
        >
          <KeyboardArrowLeftRoundedIcon
            sx={{
              fontSize: "0.75rem",
              color: route[route.length - 1] === "home" ? "white" : "dark",
            }}
            onClick={() => navigate(-1)}
          />
        </SoftBox>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={route[route.length - 1] === "home" ? "white" : "dark"}
              opacity={route[route.length - 1] === "home" ? 0.8 : 0.5}
            >
              {el}
            </SoftTypography>
          </Link>
        ))}
        <SoftTypography
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={route[route.length - 1] === "home" ? "white" : "dark"}
          noWrap
        >
          {title.replace("-", " ")}
        </SoftTypography>
      </MuiBreadcrumbs> */}
      {isSmallScreen ? (
        <MuiBreadcrumbs
          sx={{
            "& .MuiBreadcrumbs-separator": {
              color: ({ palette: { grey } }) => grey[600],
            },
          }}
        >
          <SoftBox>
            <SoftTypography
              color="dark"
              lineHeight={1.2}
              variant="h6"
            >
              {(!isSmallScreen)
                ? <span>{firstName} {lastName}</span>
                : <SoftProfile
                    src={image}
                    name={firstName}
                    size="sm"
                    fullCover
                  />
              }

            </SoftTypography>
            <SoftTypography
              color="dark"
              lineHeight={1.2}
              variant="caption"
            >
              {company}
            </SoftTypography>
          </SoftBox>
        </MuiBreadcrumbs>
      ) :  (
        <MuiBreadcrumbs
          sx={{
            "& .MuiBreadcrumbs-separator": {
              color: ({ palette: { grey } }) => grey[600],
              display: route.length && route[route.length - 1] === "home" ? "none" : "initial",
            },
          }}
        >
          {isSubscribed > 0 ? (
            <SoftBox
              sx={{
                background: "#ced4da",
                padding: "0.1rem",
                borderRadius: "50%",
              }}
            >
              <KeyboardArrowLeftRoundedIcon
                sx={{
                  fontSize: "0.75rem",
                  color: "dark",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(-1)}
              />
            </SoftBox>
          ) : null}

          {routes.map((el) => (
            <Link to={`/${el === "workspace-kpi" ? "gameboards/workspace-kpi" : el === "company-arena" ? "arena" : el}`} key={el}>
              <SoftTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color="dark"
                opacity="0.5"
              >
                {el}
              </SoftTypography>
            </Link>
          ))}
          <SoftTypography
            fontWeight="bold"
            textTransform="capitalize"
            variant="h6"
            color="dark"
            noWrap
            sx={{ marginLeft: route[route.length - 1] === "home" ? ".5rem" : 0 }}
          >
            {route[route.length - 1] === "home" ? "Dashboard" : title.replace("-", " ")}
          </SoftTypography>
        </MuiBreadcrumbs>
      )}
    </SoftBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  // icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
